import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    listItem: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        '& .matrix-MuiListItemAvatar-root': {
            minWidth: '48px',
        },
        '& .matrix-MuiAvatar-root': {
            width: '36px',
            height: '36px',
        },
    },
    avatar: {
        margin: 0,
    },
    nameRead: {
        ...theme.typography.h6,
        color: theme.palette.text.secondary,
        fontWeight: 500,
    },
    nameUnread: {
        ...theme.typography.h6,
    },
    chatBadge: {
        right: theme.spacing(1),
    },
}));
