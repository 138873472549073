import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { getDMRoomId } from 'chat/context/matrix/utils/matrixUtil';
import { useChatDispatch, useChatStateSelector } from 'chat/context/store';
import { useChatableUsers } from 'chat/hooks/use-chatable-users';
import { Spinner } from 'components';
import React, { useState } from 'react';
import { useDebounce } from 'react-use';
import { CreateChatButtons } from '../create-chat-buttons';
import { ExistingChatListItem } from './existing-chat-list-item';
import { NewChatListItem } from './new-chat-list-item';

export const NewChatDialog = () => {
    const open = useChatStateSelector((state) => state.newChatDialogOpen);
    const directs = useChatStateSelector((state) => state.directs);
    const rooms = useChatStateSelector((state) => state.rooms);
    const dispatch = useChatDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const { userIds, loadMore, canLoadMore, loading, search } = useChatableUsers();

    const existingRooms = [...rooms, ...directs];
    existingRooms.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));

    const chatableUsers = userIds.filter((userId) => !getDMRoomId(directs, userId));

    const [keyword, setKeyword] = useState('');

    const onClose = () => dispatch({ type: 'CLOSE_NEW_CHAT_DIALOG' });

    useDebounce(
        () => {
            search(keyword);
        },
        500,
        [keyword],
    );

    return (
        <Dialog open={open} onClose={onClose} fullWidth fullScreen={isMobile}>
            <DialogTitle>
                <Box width={32} height={32} />
                <Typography variant="h4">New chat</Typography>
                <IconButton size="small" onClick={onClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </DialogTitle>
            <Divider light />
            <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Box px={2}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        margin="none"
                        placeholder="Search"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <SearchOutlinedIcon style={{ color: theme.palette.text.secondary, marginRight: 12 }} />
                            ),
                            endAdornment: keyword ? (
                                <IconButton onClick={() => setKeyword('')}>
                                    <CloseOutlined />
                                </IconButton>
                            ) : null,
                        }}
                    />
                </Box>
                {!keyword && (
                    <Box mt={3}>
                        <CreateChatButtons />
                    </Box>
                )}
                <div>
                    {existingRooms.filter((room) => room.name.toLowerCase().includes(keyword.toLowerCase())).length >
                        0 && (
                        <>
                            <Box ml={2} mt={3} mb={2}>
                                <Typography variant="h4">Chats</Typography>
                            </Box>
                            {existingRooms
                                .filter((room) => room.name.toLowerCase().includes(keyword.toLowerCase()))
                                .map((chat) => (
                                    <ExistingChatListItem key={chat.roomId} chatId={chat.roomId} onClick={onClose} />
                                ))}
                        </>
                    )}
                    {chatableUsers.length > 0 && (
                        <>
                            <Box ml={2} mt={3} mb={2}>
                                <Typography variant="h4">Other contacts</Typography>
                            </Box>
                            {chatableUsers.map((userId) => (
                                <NewChatListItem key={userId} userId={userId} onClick={onClose} />
                            ))}
                            {loading && <Spinner />}
                            {canLoadMore && !loading && (
                                <Box display="flex" justifyContent="center">
                                    <Button color="primary" onClick={() => loadMore(keyword)}>
                                        Load more
                                    </Button>
                                </Box>
                            )}
                        </>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};
