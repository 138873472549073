import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
    return {
        loadMoreButton: {
            display: 'flex',
            margin: '0 auto',
        },
    };
});
