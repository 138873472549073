import { List, ListItem, ListItemIcon, ListItemText, Popover, PopoverProps, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SettingsIcon from '@material-ui/icons/Settings';
import { useChatDispatch } from 'chat/context/store';
import { useRoom } from 'chat/hooks/use-room';
import { useNotifications } from 'context/NotificationsContext';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { RoomMemberList } from '../room-member-list';
import { Settings } from '../settings';
import { useStyles } from './options-menu.style';

type OptionsMenuProps = PopoverProps & { roomId: string };

export const OptionsMenu: React.FC<OptionsMenuProps> = ({ roomId, onClose, ...props }) => {
    const classes = useStyles();
    const { confirm } = useNotifications();
    const dispatch = useChatDispatch();
    const { memberCount, isDM } = useRoom(roomId);

    const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
    const [memberListOpen, setMemberListOpen] = useState(false);

    const closeOptionsMenu = useCallback(() => onClose?.({}, 'backdropClick'), [onClose]);

    const openSettings = useCallback(() => {
        setSettingsOpen(true);
        closeOptionsMenu();
    }, [closeOptionsMenu]);

    const onCloseSettings = useCallback(() => setSettingsOpen(false), []);

    const openMemberList = useCallback(() => {
        setMemberListOpen(true);
        closeOptionsMenu();
    }, [closeOptionsMenu]);

    const closeMemberList = useCallback(() => setMemberListOpen(false), []);

    const openInviteUserDialog = useCallback(() => {
        dispatch({ type: 'OPEN_INVITE_USER_DIALOG' });
        closeOptionsMenu();
    }, [dispatch, closeOptionsMenu]);

    const leaveRoom = useCallback(
        () =>
            confirm({
                title: isDM ? 'Delete chat' : 'Leave group',
                confirmText: isDM
                    ? 'Are you sure you want to delete this chat?'
                    : 'Are you sure you want to leave this group?',
                onConfirm: () => dispatch({ type: 'LEAVE_ROOM', roomId }),
            }),
        [dispatch, confirm, roomId, isDM],
    );

    useEffect(() => {
        if (roomId) {
            onCloseSettings();
        }
    }, [roomId, onCloseSettings]);

    return (
        <Fragment>
            <Popover
                {...props}
                onClose={onClose}
                classes={{ paper: classes.root }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List disablePadding dense>
                    {!isDM && (
                        <ListItem button onClick={openInviteUserDialog}>
                            <ListItemIcon>
                                <PersonAddIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Invite</Typography>
                            </ListItemText>
                        </ListItem>
                    )}
                    {!isDM && (
                        <ListItem button onClick={openMemberList}>
                            <ListItemIcon>
                                <AccountCircleIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Members</Typography>
                            </ListItemText>
                            <Typography color="textSecondary" variant="body2">
                                {memberCount}
                            </Typography>
                        </ListItem>
                    )}
                    <ListItem button onClick={openSettings}>
                        <ListItemIcon>
                            <SettingsIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography>Settings</Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem button className={classes.leave} onClick={leaveRoom}>
                        <ListItemIcon>
                            <ExitToAppIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography>{isDM ? 'Delete chat' : 'Leave group'}</Typography>
                        </ListItemText>
                    </ListItem>
                </List>
            </Popover>
            <RoomMemberList roomId={roomId} open={memberListOpen} onClose={closeMemberList} />
            <Settings roomId={roomId} open={settingsOpen} onClose={onCloseSettings} />
        </Fragment>
    );
};
