import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
} from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './confirmation-dialog.style';

type ConfirmationDialogProps = {
    open: boolean;
    onClose: any;
    onConfirm: any;
    title: string;
    confirmText: string;
};

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    open,
    onClose,
    onConfirm,
    title,
    confirmText,
}) => {
    const classes = useStyles();
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="h4">{title}</Typography>
                <IconButton size="small" onClick={onClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </DialogTitle>
            <Divider />
            {confirmText && (
                <DialogContent>
                    <Typography className={classes.confirmText}>{confirmText}</Typography>
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={onClose} variant="text" color="primary">
                    Cancel
                </Button>
                <Button className={classes.button} onClick={onConfirm} variant="contained" color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
