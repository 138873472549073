import { createGenerateClassName, CssBaseline, StylesProvider, ThemeProvider } from '@material-ui/core';
import { NotificationsProvider } from 'context/NotificationsContext';
import { SettingsProvider } from 'context/SettingsContext';
import React, { useEffect } from 'react';
import customTheme, { PaletteType } from '../theme';
import { ChatView } from './chat-view';
import { GetInviteListFn, RoomStore } from './context/matrix/room-store';
import { CreateRoomDialog, NewChatDialog } from './room-list/components';
import { PublicRoomsDialog } from './room-list/components/public-rooms-dialog';

export type ChatProps = {
    getInviteList: GetInviteListFn;
    themeType?: PaletteType;
    fontFamily?: string;
};

export const Chat: React.FC<ChatProps> = ({ themeType, fontFamily, getInviteList }) => {
    const theme = customTheme({ type: themeType || 'light', fontFamily });

    const generateClassName = createGenerateClassName({ productionPrefix: 'jss-matrix', seed: 'matrix' });

    useEffect(() => {
        if (getInviteList) {
            RoomStore.getInviteList = getInviteList;
        }
    }, [getInviteList]);

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <SettingsProvider>
                    <NotificationsProvider>
                        <ChatView />
                        <NewChatDialog />
                        <CreateRoomDialog />
                        <PublicRoomsDialog />
                        <CssBaseline />
                    </NotificationsProvider>
                </SettingsProvider>
            </ThemeProvider>
        </StylesProvider>
    );
};
