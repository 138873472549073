import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { useChatDispatch } from 'chat/context/store';
import { useNotifications } from 'context/NotificationsContext';
import React from 'react';
import { SettingSectionItem } from '../components';

export const Leave: React.FC<{ roomId: string; type?: 'group' | 'dm' }> = ({ roomId, type = 'group' }) => {
    const { confirm } = useNotifications();
    const dispatch = useChatDispatch();

    const title = type === 'group' ? 'Leave group' : 'Delete chat';
    const confirmText =
        type === 'group' ? 'Are you sure you want to leave this group?' : 'Are you sure you want to delete this chat?';

    const leaveRoom = () =>
        confirm({
            title,
            confirmText,
            onConfirm: () => dispatch({ type: 'LEAVE_ROOM', roomId }),
        });

    return (
        <SettingSectionItem
            onClick={leaveRoom}
            icon={type === 'group' ? <ExitToAppOutlinedIcon /> : <DeleteIcon />}
            title={title}
        />
    );
};
