import { Avatar, ListItem, ListItemAvatar, ListItemText, useTheme } from '@material-ui/core';
import { matrixClient } from 'chat/context/matrix/matrix-client';
import colorMXID from 'chat/context/matrix/utils/colorMXID';
import { getDMRoomId } from 'chat/context/matrix/utils/matrixUtil';
import { useChatDispatch, useChatStateSelector } from 'chat/context/store';
import React, { useEffect, useState } from 'react';
import { useStyles } from './new-chat-list-item.style';

type NewChatListItemProps = {
    userId: string;
    onClick: (userId?: string) => void;
};

export const NewChatListItem: React.FC<NewChatListItemProps> = ({ userId, onClick }) => {
    const classes = useStyles();
    const theme = useTheme();
    const userColor = colorMXID(userId);
    const directs = useChatStateSelector((state) => state.directs);
    const dispatch = useChatDispatch();

    const [user, setUser] = useState<{ displayname?: string; avatar_url?: string } | null>(null);

    const getUserInfo = async (userId: string) => {
        const userInfo = await matrixClient.getProfileInfo(userId);
        setUser(userInfo);
    };

    useEffect(() => {
        getUserInfo(userId);
    }, [userId]);

    if (!user) {
        return null;
    }

    const { avatar_url, displayname } = user;

    const displayName = displayname || userId;

    const avatarLetter = displayName?.startsWith('@') ? displayName[1] : displayName[0];
    const avatarStyle = avatar_url ? undefined : { color: theme.palette.common.white, backgroundColor: userColor };

    const directRoomID = getDMRoomId(directs, userId);

    if (directRoomID) return null;

    const handleClick = () => {
        dispatch({
            type: 'CREATE_DIRECT_CHAT',
            userId,
            successMessage: 'Chat successfully created.',
        });
        onClick(userId);
    };

    return (
        <ListItem button alignItems="flex-start" className={classes.listItem} onClick={handleClick}>
            <ListItemAvatar className={classes.avatar}>
                <Avatar alt={displayName} src={avatar_url || undefined} style={avatarStyle}>
                    {avatarLetter}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={displayName} primaryTypographyProps={{ noWrap: true }} />
        </ListItem>
    );
};
