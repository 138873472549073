import { darken, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        borderLeft: `1px solid ${theme.palette.divider}`,
        position: 'relative',
    },
    emptyTextPlaceholder: {
        backgroundColor: darken(theme.palette.background.paper, 0.1),
        padding: '4px 12px',
        borderRadius: '16px',
    },
}));
