import { makeStyles } from '@material-ui/core';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;

    return {
        header: {
            padding: theme.spacing(2),
            backgroundColor: isDarkMode ? 'default' : '#f6f6f7',
            minHeight: 60,
            height: 60,
            color: '0dbd8b',
            position: 'sticky',
            width: '100%',
            top: 0,
            zIndex: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        back: {
            color: theme.palette.primary.main,
        },
        edit: {
            color: theme.palette.primary.main,
        },
    };
});
