import { MatrixSetup } from 'chat/context/matrix/matrix-setup';
import { EventType } from 'matrix-js-sdk/lib/@types/event';
import { Dispatch, Reducer, useCallback, useEffect, useMemo } from 'react';
import { createContainer } from 'react-tracked';
import { useReducerAsync } from 'use-reducer-async';
import { ConfigStore } from './matrix/config-store';
import { matrixClient } from './matrix/matrix-client';
import { MatrixAuthData, MatrixEventType } from './matrix/types';
import { asyncActionHandlers, AsyncChatAction, ChatAction, ChatState, INITIAL_CHAT_STATE, reducer } from './reducer';

type StoreProps = {
    baseUrl: string;
    matrixAuth: MatrixAuthData;
};

const useValue = ({ baseUrl, matrixAuth }: StoreProps): [ChatState, Dispatch<ChatAction | AsyncChatAction>] => {
    const [state, dispatch] = useReducerAsync<Reducer<ChatState, ChatAction>, AsyncChatAction>(
        reducer,
        INITIAL_CHAT_STATE,
        asyncActionHandlers,
    );

    const matrixSetup = useMemo(() => new MatrixSetup(baseUrl), [baseUrl]);

    const loadRooms = useCallback(() => dispatch({ type: 'LOAD_ROOMS' }), [dispatch]);
    const chatIsReady = state.chatIsReady;

    useEffect(() => {
        // TODO: Remove this later
        globalThis.state = state;
        globalThis.dispatch = dispatch;
        globalThis.matrix = matrixSetup;
    }, [state, dispatch, matrixSetup]);

    useEffect(() => {
        if (matrixAuth) {
            ConfigStore.setMatrixAuthData(matrixAuth);
            dispatch({ type: 'LOGGED_IN' });

            matrixSetup.once('init_loading_finished', () => {
                dispatch({ type: 'CHAT_LOADED' });
            });

            matrixSetup.init();
        }
    }, [dispatch, matrixSetup, matrixAuth]);

    useEffect(() => {
        if (chatIsReady) {
            loadRooms();
            matrixClient.on(EventType.RoomAvatar, loadRooms);
            matrixClient.on(MatrixEventType.AccountData, loadRooms);
            matrixClient.on(MatrixEventType.RoomName, loadRooms);
            matrixClient.on(MatrixEventType.RoomStateEvents, loadRooms);
            matrixClient.on(MatrixEventType.RoomMyMembership, loadRooms);
            matrixClient.on(MatrixEventType.RoomTimeline, loadRooms);
        }
        return () => {
            if (chatIsReady) {
                matrixClient.removeListener(EventType.RoomAvatar, loadRooms);
                matrixClient.removeListener(MatrixEventType.AccountData, loadRooms);
                matrixClient.removeListener(MatrixEventType.RoomName, loadRooms);
                matrixClient.removeListener(MatrixEventType.RoomStateEvents, loadRooms);
                matrixClient.removeListener(MatrixEventType.RoomMyMembership, loadRooms);
                matrixClient.removeListener(MatrixEventType.RoomTimeline, loadRooms);
            }
        };
    }, [loadRooms, chatIsReady]);

    return [state, dispatch];
};

export const {
    Provider: ChatProvider,
    useTracked: useChat,
    useTrackedState: useChatState,
    useUpdate: useChatDispatch,
    useSelector: useChatStateSelector,
} = createContainer(useValue);
