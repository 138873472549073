import {
    Box,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Typography,
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Delete as DeleteIcon, MoreVert as MoreVertIcon } from '@material-ui/icons';
import { matrixClient } from 'chat/context/matrix/matrix-client';
import { TimelineStore } from 'chat/context/matrix/timeline-store';
import colorMXID from 'chat/context/matrix/utils/colorMXID';
import { isMedia } from 'chat/context/matrix/utils/matrixUtil';
import { useChatState } from 'chat/context/store';
import { useRoom } from 'chat/hooks/use-room';
import { Popover } from 'components';
import { useNotifications } from 'context/NotificationsContext';
import { DateTime } from 'luxon';
import { IContent, MatrixEvent, RoomMember } from 'matrix-js-sdk';
import React, { useMemo } from 'react';
import Linkify from 'react-linkify';
import * as Media from '../media';
import { SenderAvatar } from '../sender-avatar';
import { useStyles } from './message.style';

export type MessageProps = {
    message: MatrixEvent;
    prevEvent?: MatrixEvent;
};

const MessageComponent: React.FC<MessageProps> = ({ message, prevEvent }) => {
    const classes = useStyles({ replyBorderColor: '#ccc' });

    const {
        pageStartIds,
        eventEdits: { [message.getId()]: edits },
        activeRoomId,
    } = useChatState();

    const contentOnly = useMemo(() => {
        let contentOnly = false;

        const getAvatarUrl = (member: RoomMember) =>
            member.getAvatarUrl(matrixClient.baseUrl, 36, 36, 'crop', true, true);

        if (prevEvent && !pageStartIds.includes(message.getId())) {
            if (
                prevEvent.getType() === 'm.room.message' &&
                prevEvent.sender.userId === message.sender.userId &&
                getAvatarUrl(prevEvent.sender) === getAvatarUrl(message.sender)
            ) {
                const prevEventDate = DateTime.fromJSDate(prevEvent.getDate() || new Date());
                const currEventDate = DateTime.fromJSDate(message.getDate() || new Date());
                const diffInMinutes = currEventDate.diff(prevEventDate, 'minute').minutes;
                if (diffInMinutes <= 5) {
                    contentOnly = true;
                }
            }
        }

        return contentOnly;
    }, [message, pageStartIds, prevEvent]);

    const isEdited = edits && edits.length > 0;

    const { sender } = message;
    const senderColor = useMemo(() => colorMXID(sender.userId), [sender.userId]);
    const senderName = sender.name || sender.userId;

    const date = DateTime.fromJSDate(message.getDate() || new Date()).toFormat('HH:mm');

    const content = message.getContent();

    const { body, format, formatted_body } = content['m.new_content'] || content;

    const isHtml = format === 'org.matrix.custom.html';

    return (
        <Box display="flex" className={classes.root}>
            <SenderAvatar sender={sender} empty={contentOnly} />
            <Box display="flex" flexDirection="column" className={classes.messageContent}>
                <Box
                    pb={0.5}
                    display={contentOnly ? 'none' : 'flex'}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography className={classes.senderName} style={{ color: senderColor }}>
                        {senderName}
                    </Typography>
                    <Typography className={classes.date}>{date}</Typography>
                </Box>

                {isMedia(message) ? (
                    getMediaContent(content)
                ) : (
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" href={decoratedHref} key={key} rel="noopener noreferrer">
                                {decoratedText}
                            </a>
                        )}
                    >
                        <Box
                            className={classes.message}
                            dangerouslySetInnerHTML={isHtml ? { __html: formatted_body } : undefined}
                        >
                            {isHtml ? null : body}
                        </Box>
                    </Linkify>
                )}
                {isEdited && (
                    <Box>
                        <Typography className={classes.date}>(edited)</Typography>
                    </Box>
                )}
            </Box>
            {activeRoomId && <MessageOptions message={message} classes={classes} roomId={activeRoomId} />}
        </Box>
    );
};

export const Message = React.memo(MessageComponent);

const MessageOptions: React.FC<{ message: MatrixEvent; classes: ClassNameMap<string>; roomId: string }> = ({
    message,
    classes,
    roomId,
}) => {
    const { room } = useRoom(roomId);
    const { confirm } = useNotifications();
    const canIRedact = room?.currentState.maySendRedactionForEvent(message, matrixClient.getUserId());

    if (!canIRedact) return null;

    return (
        <Box className={classes.messagePopover}>
            <Popover
                anchorEl={(props) => (
                    <IconButton size="small" {...props}>
                        <MoreVertIcon fontSize="small" />
                    </IconButton>
                )}
                classes={{ paper: classes.messageOptions }}
                disablePortal
                disableScrollLock
            >
                <List disablePadding dense>
                    <ListSubheader style={{ lineHeight: '36px' }}>Options</ListSubheader>
                    <Divider />
                    <ListItem
                        className={classes.messageOptionsDelete}
                        button
                        onClick={() =>
                            confirm({
                                title: 'Delete message',
                                confirmText: 'Are you sure you want to delete this message?',
                                onConfirm: () => TimelineStore.redactEvent(roomId, message.getId()),
                            })
                        }
                    >
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography>Delete</Typography>
                        </ListItemText>
                    </ListItem>
                </List>
            </Popover>
        </Box>
    );
};

const getMediaContent = (content: IContent) => {
    const mediaMXC = content.url;
    const mediaLink = matrixClient.mxcUrlToHttp(mediaMXC);
    if (!mediaLink) return null;
    switch (content.msgtype) {
        case 'm.file':
            return <Media.File name={content.body} link={mediaLink} />;
        case 'm.image':
            return (
                <Media.Image
                    name={content.body}
                    width={typeof content.info?.w === 'number' ? content.info?.w : null}
                    height={typeof content.info?.h === 'number' ? content.info?.h : null}
                    link={mediaLink}
                />
            );
        case 'm.audio':
            return <Media.Audio name={content.body} link={mediaLink} type={content.info?.mimetype} />;
        case 'm.video':
            let thumbnailMXC = content.info?.thumbnail_url;
            const thumbnailLink = matrixClient.mxcUrlToHttp(thumbnailMXC);
            if (typeof thumbnailMXC === 'undefined') {
                thumbnailMXC = content.info?.thumbnail_file?.url || null;
            }
            return (
                <Media.Video
                    name={content.body}
                    link={mediaLink}
                    thumbnail={thumbnailMXC === null ? null : thumbnailLink}
                    width={typeof content.info?.w === 'number' ? content.info?.w : null}
                    height={typeof content.info?.h === 'number' ? content.info?.h : null}
                    type={content.info?.mimetype}
                />
            );
    }
};
