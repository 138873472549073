import React, { useState } from 'react';
import { IconButton, TextField, List, Box, Typography, Button } from '@material-ui/core';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import { useDebounce } from 'react-use';
import { RoomStore } from 'chat/context/matrix/room-store';
import { PublicRoomItem } from '../public-room-item';
import { Spinner } from 'components';
import { useStyles } from './public-rooms-form.style';

export const PublicRoomsForm: React.FC = () => {
    const [publicRooms, setPublicRooms] = useState<any>([]);
    const [keyword, setKeyword] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const [nextBatch, setNextBatch] = useState<string | undefined>();

    const classes = useStyles();

    const viewMore = async () => {
        if (nextBatch) {
            setLoading(true);
            const result = await RoomStore.getPublicRooms(keyword, nextBatch);
            setPublicRooms((val) => val.concat(result.chunk));
            setNextBatch(result.nextBatch);
            setLoading(false);
        }
    };

    const search = async () => {
        setLoading(true);
        const result = await RoomStore.getPublicRooms(keyword);
        setNextBatch(result.nextBatch);
        setPublicRooms(result.chunk);
        setLoading(false);
    };

    useDebounce(
        () => {
            setPublicRooms([]);
            search();
        },
        500,
        [keyword],
    );

    const isEmpty = publicRooms.length === 0;

    return (
        <>
            <TextField
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="Search for user..."
                variant="outlined"
                fullWidth
                size="small"
                margin="none"
                InputProps={{
                    endAdornment: keyword ? (
                        <IconButton onClick={() => setKeyword('')}>
                            <CloseOutlined />
                        </IconButton>
                    ) : null,
                }}
            />

            <Box mt={2}>
                <Box mb={2}>
                    {!isEmpty && (
                        <List>
                            {publicRooms.map((room, index) => {
                                return (
                                    <PublicRoomItem room={room} key={index} numberOfMembers={room.num_joined_members} />
                                );
                            })}
                        </List>
                    )}
                    {loading && <Spinner style={{ height: 100 }} />}
                    {isEmpty && !loading && (
                        <Box height={100} display="flex" justifyContent="center" alignItems="center">
                            <Typography variant="h6">No results</Typography>
                        </Box>
                    )}
                </Box>
                {nextBatch && (
                    <Button onClick={viewMore} color="primary" className={classes.loadMoreButton} disabled={loading}>
                        View more
                    </Button>
                )}
            </Box>
        </>
    );
};
