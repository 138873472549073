import { Box, IconButton, List, Paper, SvgIcon, TextField, Typography, useTheme } from '@material-ui/core';
import { ReactComponent as EditIcon } from 'assets/icons/square-edit-outline.svg';
import { useChatDispatch, useChatState } from 'chat/context/store';
import React, { useMemo, useState } from 'react';
import { CollapsibleList, RoomListItem } from './components';
import { useStyles } from './room-list.style';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import CloseOutlined from '@material-ui/icons/CloseOutlined';

const RoomListComponent: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { activeRoomId, rooms, directs, invites } = useChatState();
    const dispatch = useChatDispatch();
    const [keyword, setKeyword] = useState('');

    const filteredRooms = useMemo(
        () => rooms.filter((room) => room.name.toLowerCase().includes(keyword.toLowerCase())),
        [rooms, keyword],
    );

    const filteredDirects = useMemo(
        () => directs.filter((direct) => direct.name.toLowerCase().includes(keyword.toLowerCase())),
        [directs, keyword],
    );

    const filteredInvites = useMemo(
        () => invites.filter((invite) => invite.name.toLowerCase().includes(keyword.toLowerCase())),
        [invites, keyword],
    );

    const renderRoom = (roomId: string) => {
        return <RoomListItem key={roomId} roomId={roomId} selected={roomId === activeRoomId} />;
    };

    const openNewChatDialog = () => dispatch({ type: 'OPEN_NEW_CHAT_DIALOG' });

    return (
        <Box className={classes.root}>
            <Box className={classes.header}>
                <Typography variant="h5">Conversations</Typography>
                <IconButton onClick={openNewChatDialog}>
                    <SvgIcon component={EditIcon} />
                </IconButton>
            </Box>
            <Box className={classes.content}>
                <Box my={1} px={1}>
                    <Paper elevation={0}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            margin="none"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <SearchOutlinedIcon
                                        style={{ color: theme.palette.text.secondary, marginRight: 12 }}
                                    />
                                ),
                                endAdornment: keyword ? (
                                    <IconButton onClick={() => setKeyword('')}>
                                        <CloseOutlined />
                                    </IconButton>
                                ) : null,
                            }}
                        />
                    </Paper>
                </Box>
                {filteredInvites.length > 0 && (
                    <CollapsibleList title="Invites">
                        <List component="nav" className={classes.list}>
                            {filteredInvites.map((invite) => renderRoom(invite.roomId))}
                        </List>
                    </CollapsibleList>
                )}
                {filteredDirects.length > 0 && (
                    <CollapsibleList title="Directs">
                        <List component="nav" className={classes.list}>
                            {filteredDirects.map((direct) => renderRoom(direct.roomId))}
                        </List>
                    </CollapsibleList>
                )}
                {filteredRooms.length > 0 && (
                    <CollapsibleList title="Groups">
                        <List component="nav" className={classes.list}>
                            {filteredRooms.map((room) => renderRoom(room.roomId))}
                        </List>
                    </CollapsibleList>
                )}
                {filteredDirects.length === 0 && filteredInvites.length === 0 && filteredRooms.length === 0 && keyword && (
                    <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
                        <Typography variant="h6">No results found</Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export const RoomList = React.memo(RoomListComponent);
