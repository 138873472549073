import { Reducer } from 'react';
import { AsyncActionHandlers } from 'use-reducer-async';
import { RoomStore } from '../matrix/room-store';
import { ChatState } from '../reducer';

type JoinRoomStartedAction = { type: 'JOIN_ROOM_STARTED' };
type JoinRoomFailedAction = { type: 'JOIN_ROOM_FAILED'; error?: string };
type JoinRoomSuccessAction = {
    type: 'JOIN_ROOM_SUCCESS';
};

export type JoinRoomAsyncRoutine = JoinRoomStartedAction | JoinRoomFailedAction | JoinRoomSuccessAction;

export type JoinRoomAsync = {
    type: 'JOIN_ROOM';
    roomId: string;
    onSuccess?: () => void;
};

export const JoinRoomHandlers: AsyncActionHandlers<Reducer<ChatState, JoinRoomAsyncRoutine>, JoinRoomAsync> = {
    JOIN_ROOM: ({ dispatch, signal }) => async (action) => {
        try {
            dispatch({ type: 'JOIN_ROOM_STARTED' });
            await RoomStore.joinRoom(action);
            if (!signal.aborted) {
                dispatch({ type: 'JOIN_ROOM_SUCCESS' });
            }
            if (action.onSuccess) {
                action.onSuccess();
            }
        } catch (error) {
            console.log(error);
            if (!signal.aborted) {
                const errcode = (error as { errcode: string }).errcode;
                console.log({ errcode });
                const errcodes = [
                    'M_UNKNOWN',
                    'M_BAD_JSON',
                    'M_INVALID_PARAM',
                    'M_FORBIDDEN',
                    'M_LIMIT_EXCEEDED',
                    'M_UNSUPPORTED_ROOM_VERSION',
                ];

                const errorMessage = errcodes.includes(errcode) ? errcode : undefined;

                dispatch({ type: 'JOIN_ROOM_FAILED', error: errorMessage });
            }
            console.error(error, action.type);
        }
    },
};
