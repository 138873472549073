import { PopoverProps, Popover as MUIPopover } from '@material-ui/core';
import React, { useState } from 'react';

export const Popover: React.FC<
    Omit<PopoverProps, 'anchorEl' | 'open'> & {
        anchorEl: (props: { onClick: (e) => void }) => React.ReactElement;
    }
> = ({ anchorEl, children, ...props }) => {
    const [anchor, setAnchor] = useState(null);

    const openPopover = (e) => {
        setAnchor(e.currentTarget);
    };

    const closePopover = () => {
        setAnchor(null);
    };

    if (!anchorEl) return null;

    return (
        <>
            {anchorEl({ onClick: openPopover })}
            <MUIPopover
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorEl={anchor}
                open={!!anchor}
                onClose={closePopover}
                {...props}
            >
                {children}
            </MUIPopover>
        </>
    );
};
