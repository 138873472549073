import { Box, Typography, Avatar, TextField, useTheme } from '@material-ui/core';
import React from 'react';
import { useStyles } from './avatar-section.style';
import { Field } from 'formik';
import { useRoom } from 'chat/hooks/use-room';

type AvatarSectionProps = {
    roomId: string;
    values: { title: string };
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};
export const AvatarSection: React.FC<AvatarSectionProps> = ({ roomId, values, setFieldValue }) => {
    const classes = useStyles();
    const theme = useTheme();

    const { name, avatarUrl, avatarLetter, color, members } = useRoom(roomId);

    const avatarStyle = avatarUrl ? undefined : { color: theme.palette.common.white, backgroundColor: color };

    return (
        <Box className={classes.avatarSection}>
            <Avatar alt={name} src={avatarUrl || undefined} style={avatarStyle} className={classes.avatar}>
                {avatarLetter}
            </Avatar>
            <Field
                component={TextField}
                name="title"
                value={values.title}
                className={classes.textField}
                onChange={(e) => {
                    setFieldValue('title', e.target.value);
                }}
                placeholder="Group name"
                style={{ width: values.title ? (values.title.length + 1) * 11 : 120 }}
            />

            <Typography color="textSecondary">{members.length} members</Typography>
        </Box>
    );
};
