import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        listItem: {
            paddingLeft: 0,
            paddingRight: 0,
            minHeight: 72,
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                alignItems: 'initial',
                '& > *:not(:last-child)': {
                    marginBottom: theme.spacing(1),
                },
            },
        },
        listItemContainer: {
            '& .matrix-MuiListItemSecondaryAction-root': {
                minWidth: 90,
                [theme.breakpoints.down('xs')]: {
                    top: 8,
                },
            },
        },
        actionButton: {
            [theme.breakpoints.down('xs')]: {
                marginLeft: 'auto',
            },
            minWidth: '78px',
        },
        join: {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
        },
        view: {
            backgroundColor: theme.palette.primary.main,
            color: '#ffffff',
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
        },
        details: {
            fontSize: '.75rem',
        },
        members: {
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(0.5),
            '& p': {
                fontWeight: 400,
                fontSize: 12,
            },
            '& svg': {
                width: 16,
                height: 16,
                marginRight: theme.spacing(0.75),
                color: theme.palette.text.secondary,
            },
        },
        publicRoomInfo: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginRight: theme.spacing(3),
            [theme.breakpoints.down('xs')]: {
                marginRight: theme.spacing(1),
            },
        },
    };
});
