import { RoomStore } from 'chat/context/matrix/room-store';
import { useState } from 'react';

export const useChatableUsers = (): {
    userIds: string[];
    isEmpty: boolean;
    loading: boolean;
    loadMore: (keyword: string) => Promise<void>;
    search: (keyword: string) => Promise<void>;
    canLoadMore: boolean;
} => {
    const [userIds, setUserIds] = useState<string[]>([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);

    const isEmpty = userIds.length === 0;

    const search = async (keyword: string) => {
        setLoading(true);
        const result = await RoomStore.getInviteList({ keyword, page: 1 });
        setUserIds(result.userIds);
        setHasNextPage(result.hasNextPage);
        setPage(1);
        setLoading(false);
    };

    const loadMoreUsers = async (keyword: string) => {
        setLoading(true);
        const result = await RoomStore.getInviteList({ keyword, page: page + 1 });
        setUserIds((prev) => [...prev, ...result.userIds]);
        setHasNextPage(result.hasNextPage);
        setPage((prev) => prev + 1);
        setLoading(false);
    };

    return { userIds, isEmpty, loading, loadMore: loadMoreUsers, canLoadMore: hasNextPage, search };
};
