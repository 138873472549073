import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useChat } from 'chat/context/store';
import React, { useCallback } from 'react';
import { useStyles } from './public-rooms-dialog.style';
import { PublicRoomsForm } from './public-rooms-form';

export const PublicRoomsDialog: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles();

    const [{ publicRoomsDialogOpen }, dispatch] = useChat();

    const closeDialog = () => dispatch({ type: 'CLOSE_PUBLIC_ROOMS_DIALOG' });

    const openCreateRoomDialog = useCallback(() => {
        dispatch({ type: 'CLOSE_PUBLIC_ROOMS_DIALOG' });
        dispatch({ type: 'OPEN_CREATE_ROOM_DIALOG' });
    }, [dispatch]);

    return (
        <Dialog fullWidth maxWidth="sm" open={publicRoomsDialogOpen} fullScreen={isMobile}>
            <DialogTitle>
                <Typography variant="h4">Explore public groups</Typography>

                <IconButton size="small" onClick={closeDialog}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ paddingTop: 0 }}>
                <Box mb={3}>
                    <Typography color="textSecondary">
                        If you can not find the group you are looking for, ask for an invite or
                        <span onClick={openCreateRoomDialog} className={classes.action}>
                            {' ' + 'Create a new group.'}
                        </span>
                    </Typography>
                </Box>
                <PublicRoomsForm />
            </DialogContent>
            <Divider />
        </Dialog>
    );
};
