import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
// import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { useChatDispatch } from 'chat/context/store';
import React from 'react';
import { useStyles } from './create-chat-buttons.style';

export const CreateChatButtons = () => {
    const classes = useStyles();
    const dispatch = useChatDispatch();

    const openCreateRoomDialog = () => {
        dispatch({ type: 'OPEN_CREATE_ROOM_DIALOG' });
        dispatch({ type: 'CLOSE_NEW_CHAT_DIALOG' });
    };
    const openPublicRoomsDialog = () => {
        dispatch({ type: 'OPEN_PUBLIC_ROOMS_DIALOG' });
        dispatch({ type: 'CLOSE_NEW_CHAT_DIALOG' });
    };

    return (
        <List disablePadding className={classes.root}>
            <ListItem button onClick={openCreateRoomDialog}>
                <ListItemIcon>
                    <GroupAddIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                    <Typography>Create new group</Typography>
                </ListItemText>
            </ListItem>
            <ListItem button onClick={openPublicRoomsDialog}>
                <ListItemIcon>
                    <SearchOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                    <Typography>Join public groups</Typography>
                </ListItemText>
            </ListItem>
        </List>
    );
};
