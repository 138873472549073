import { Avatar, ListItem, ListItemAvatar, ListItemText, useTheme } from '@material-ui/core';
import { useChatDispatch } from 'chat/context/store';
import { useRoom } from 'chat/hooks/use-room';
import React, { useCallback } from 'react';
import { useStyles } from './existing-chat-list-item.style';

type ExistingChatListItemProps = {
    chatId: string;
    onClick: (chatId?: string) => void;
};

export const ExistingChatListItem: React.FC<ExistingChatListItemProps> = ({ chatId, onClick }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useChatDispatch();
    const { name, avatarLetter, avatarUrl, color } = useRoom(chatId);

    const handleClick = useCallback(() => {
        dispatch({ type: 'ROOM_SELECTED', roomId: chatId });
        onClick(chatId);
    }, [dispatch, chatId, onClick]);

    const avatarStyle = avatarUrl ? undefined : { color: theme.palette.common.white, backgroundColor: color };

    return (
        <ListItem button alignItems="flex-start" className={classes.listItem} onClick={handleClick}>
            <ListItemAvatar className={classes.avatar}>
                <Avatar alt={name} src={avatarUrl || undefined} style={avatarStyle}>
                    {avatarLetter}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={name} primaryTypographyProps={{ noWrap: true }} />
        </ListItem>
    );
};
