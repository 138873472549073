import { matrixClient } from 'chat/context/matrix/matrix-client';
import colorMXID from 'chat/context/matrix/utils/colorMXID';
import { useChatStateSelector } from 'chat/context/store';
import { NotificationCountType, Room, RoomMember } from 'matrix-js-sdk';
import { useMemo } from 'react';

export type RoomHook = {
    room: Room;
    name: string;
    avatarUrl: string | null;
    avatarLetter: string;
    color?: string;
    unreadNotificationsCount?: number;
    members: RoomMember[];
    memberCount: number;
    topic?: string;
    isDM: boolean;
    isJoined: any;
};

export const useRoom = (roomId: string): RoomHook => {
    const room = useChatStateSelector((s) => s.allRooms[roomId]);

    const members = useChatStateSelector((s) => s.allRooms[roomId]?.getMembersWithMembership('join') || []);

    const isDM = useChatStateSelector((s) =>
        s.directIds.some((directId) => {
            return directId === roomId;
        }),
    );
    const name: string = useChatStateSelector((s) => {
        const me = matrixClient.getUserId();

        if (isDM) {
            const member = members.find((member) => {
                member.userId !== me;
            });
            return member ? member.rawDisplayName : s.allRooms[roomId]?.name;
        } else {
            return s.allRooms[roomId]?.name || roomId;
        }
    });

    const fallbackAvatarUrl = useChatStateSelector((s) =>
        s.allRooms[roomId]?.getAvatarFallbackMember()?.getAvatarUrl(matrixClient.baseUrl, 40, 40, 'crop', true, true),
    );

    const roomAvatarUrl = useChatStateSelector((s) => {
        let url = s.allRooms[roomId]?.getAvatarUrl(matrixClient.baseUrl, 40, 40, 'crop');

        url = isDM
            ? matrixClient
                  .getRoom(roomId)
                  .getAvatarFallbackMember()
                  ?.getAvatarUrl(matrixClient.baseUrl, 36, 36, 'crop', true, true)
            : url;

        return url;
    });

    const unreadNotificationsCount = useChatStateSelector((s) =>
        s.allRooms[roomId]?.getUnreadNotificationCount(NotificationCountType.Total),
    );

    const memberCount = useChatStateSelector((s) => s.allRooms[roomId]?.currentState.getJoinedMemberCount());

    const topic = useChatStateSelector(
        (s) =>
            s.allRooms[roomId]?.currentState.getStateEvents('m.room.topic')[0]?.getContent().topic as
                | string
                | undefined,
    );

    const isJoined = useChatStateSelector((s) => {
        return s.allRooms[roomId] ? true : false;
    });

    const color = useMemo(() => colorMXID(roomId), [roomId]);
    const avatarUrl = roomAvatarUrl || fallbackAvatarUrl;
    const avatarLetter = name?.startsWith('#') ? name[1] : name[0];

    return {
        room,
        name,
        avatarUrl,
        avatarLetter: avatarLetter.toUpperCase(),
        color,
        unreadNotificationsCount,
        members,
        memberCount,
        topic,
        isDM,
        isJoined,
    };
};
