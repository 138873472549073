import { darken, lighten, makeStyles } from '@material-ui/core';
import { Theme } from 'enum';

export const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;
    const colorTransform = isDarkMode ? lighten : darken;
    return {
        root: {
            '& .matrix-MuiListItem-root': {
                borderRadius: 0,
                color: theme.palette.primary.main,
            },
            '& .matrix-MuiListItemIcon-root': {
                minWidth: 'unset',
                backgroundColor: colorTransform(theme.palette.background.default, 0.03),
                padding: theme.spacing(1),
                borderRadius: '50%',
                marginRight: theme.spacing(2),

                '& .matrix-MuiSvgIcon-root': {
                    fill: theme.palette.primary.main,
                },
            },
        },
    };
});
