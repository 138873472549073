import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        searchBar: {
            width: '100%',
        },
        details: {
            color: theme.palette.grey[600],
        },
        action: {
            color: theme.palette.info.main,
            cursor: 'pointer',
        },
    };
});
