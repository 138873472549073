import { makeStyles } from '@material-ui/core';
import { Theme } from 'enum';

interface StyleProps {
    disabled: boolean | undefined;
}

export const useStyles = makeStyles((theme) => {
    const isDarkMode = theme.palette.type === Theme.Dark;

    return {
        root: ({ disabled }: StyleProps) => ({
            backgroundColor: theme.palette.background.default,
            borderRadius: theme.spacing(2),
            boxShadow: `0px 1px 4px 0px ${isDarkMode ? theme.shadows[0] : '#959DA529'}`,
            display: 'flex',
            padding: theme.spacing(2),
            flexDirection: 'column',
            alignItems: 'center',
            color: theme.palette.primary.main,
            cursor: disabled ? 'default' : 'pointer',
        }),
        title: {
            fontWeight: 400,
            fontSize: '.875rem',
            marginTop: theme.spacing(0.5),
        },
    };
});
