import {
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    useTheme,
    Popover,
    List,
    ListItemIcon,
    ListItemSecondaryAction,
    IconButton,
} from '@material-ui/core';
import { matrixClient } from 'chat/context/matrix/matrix-client';
import colorMXID from 'chat/context/matrix/utils/colorMXID';
import { RoomMember } from 'matrix-js-sdk';
import React, { useMemo, useCallback, useEffect } from 'react';
import { useStyles } from './room-member-item.style';
import { useChat } from 'chat/context/store';
import { useNotifications } from 'context/NotificationsContext';
import { canIKick, isMe } from 'chat/context/matrix/utils/matrixUtil';
import { useRoom } from 'chat/hooks/use-room';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

type RoomMemberItemProps = { member: RoomMember; roomId: string };

export const RoomMemberItem: React.FC<RoomMemberItemProps> = ({ member, roomId }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { notify, confirm } = useNotifications();
    const { room } = useRoom(roomId);

    const avatarUrl = member.getAvatarUrl(matrixClient.baseUrl, 36, 36, 'crop', true, true);
    const memberColor = useMemo(() => colorMXID(member.userId), [member.userId]);
    const memberName = member.name || member.userId;

    const avatarLetter = memberName.startsWith('@') ? memberName[1] : memberName[0];
    const avatarStyle = avatarUrl ? undefined : { color: theme.palette.common.white, backgroundColor: memberColor };

    const roomMember = room.getMember(member.userId);

    const myPowerLevel = room.getMember(matrixClient.getUserId())?.powerLevel || 0;
    const powerLevel = roomMember?.powerLevel || 0;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [{ kickFromRoomLoading, kickFromRoomError }, dispatch] = useChat();

    const kickFromRoom = useCallback(
        () =>
            confirm({
                title: 'Remove user',
                confirmText: `Are you sure you want remove the  ${member.name} from this group?`,
                onConfirm: () => dispatch({ type: 'KICK_FROM_ROOM', roomId, userId: member.userId }),
            }),
        [dispatch, confirm, roomId, member],
    );

    useEffect(() => {
        if (kickFromRoomError) {
            notify({ type: 'error', message: kickFromRoomError });
        }
    }, [kickFromRoomError, notify]);

    return (
        <>
            <ListItem>
                <ListItemAvatar className={classes.avatarContainer}>
                    <Avatar className={classes.avatar} src={avatarUrl || undefined} style={avatarStyle}>
                        {avatarLetter}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText>
                    <Typography>{member.rawDisplayName}</Typography>
                </ListItemText>
                <ListItemSecondaryAction>
                    <IconButton onClick={handleClick}>
                        <MoreHorizIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                classes={{ paper: classes.listContainer }}
            >
                <List disablePadding dense>
                    {!isMe(member) && (
                        <ListItem button>
                            <ListItemIcon>
                                <MailOutlineIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Message</Typography>
                            </ListItemText>
                        </ListItem>
                    )}
                    <ListItem button>
                        <ListItemIcon>
                            <AccountCircleIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography>See profile</Typography>
                        </ListItemText>
                    </ListItem>
                    {canIKick(roomMember, myPowerLevel, powerLevel, room) && (
                        <ListItem button onClick={kickFromRoom} disabled={kickFromRoomLoading}>
                            <ListItemIcon>
                                <RemoveCircleOutlineIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Remove user</Typography>
                            </ListItemText>
                        </ListItem>
                    )}
                </List>
            </Popover>
        </>
    );
};
