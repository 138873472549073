import React, { useState, useCallback } from 'react';
import {
    ListItem,
    ListItemText,
    Typography,
    ListItemAvatar,
    Avatar,
    useTheme,
    Box,
    Button,
    ListItemSecondaryAction,
} from '@material-ui/core';
import { IPublicRoomsChunkRoom } from 'matrix-js-sdk';
import { useRoom } from 'chat/hooks/use-room';
import { useStyles } from './public-room-item.style';
import { useChatDispatch } from 'chat/context/store';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import clsx from 'clsx';

type PublicRoomItemProps = {
    numberOfMembers: number;
    room: IPublicRoomsChunkRoom;
};

export const PublicRoomItem: React.FC<PublicRoomItemProps> = ({ room, numberOfMembers, ...props }) => {
    const { color } = useRoom(room.room_id);
    const classes = useStyles();
    const theme = useTheme();

    const { isJoined } = useRoom(room.room_id);

    const dispatch = useChatDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    const actionButtonHandler = useCallback(() => {
        if (!isJoined) {
            setLoading(true);
            dispatch({
                type: 'JOIN_ROOM',
                roomId: room.room_id,
                onSuccess: () => {
                    setLoading(false);
                    dispatch({ type: 'CLOSE_PUBLIC_ROOMS_DIALOG' });
                    dispatch({ type: 'ROOM_SELECTED', roomId: room.room_id });
                },
            });
        } else {
            dispatch({ type: 'ROOM_SELECTED', roomId: room.room_id });
            dispatch({ type: 'CLOSE_PUBLIC_ROOMS_DIALOG' });
        }
    }, [dispatch, room.room_id, isJoined]);

    const avatarStyle = room.avatar_url ? undefined : { color: theme.palette.common.white, backgroundColor: color };

    const avatarLetter = (name) => {
        return name.startsWith('#') ? name.trim()[1].toUpperCase() : name.trim()[0].toUpperCase();
    };

    return (
        <ListItem {...props} className={classes.listItem} classes={{ container: classes.listItemContainer }}>
            <ListItemAvatar>
                <Avatar src={room?.avatar_url || undefined} style={avatarStyle}>
                    {avatarLetter(room.name)}
                </Avatar>
            </ListItemAvatar>
            <ListItemText>
                <Box className={classes.publicRoomInfo}>
                    <Box>
                        <Typography variant="h6">{room?.name}</Typography>
                        <Typography color="textSecondary" className={classes.details}>
                            {room.topic}
                        </Typography>
                    </Box>
                </Box>
            </ListItemText>

            <ListItemSecondaryAction>
                <Button
                    onClick={actionButtonHandler}
                    disabled={loading}
                    className={clsx(classes.actionButton, isJoined ? classes.join : classes.view)}
                    variant={isJoined ? 'outlined' : 'contained'}
                    fullWidth
                >
                    {loading ? 'Joining' : isJoined ? 'View' : 'Join'}
                </Button>
                <Box className={classes.members}>
                    <PersonOutlineIcon />
                    <Typography color="textSecondary">{numberOfMembers} members</Typography>
                </Box>
            </ListItemSecondaryAction>
        </ListItem>
    );
};
