import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { SecuritySectionItem } from '../security-section-item';
import { useStyles } from './room-visibility.style';

type RoomVisibilityProps = {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    values: any;
};

export const RoomVisibility: React.FC<RoomVisibilityProps> = ({ setFieldValue, values }) => {
    const classes = useStyles();
    return (
        <SecuritySectionItem
            title="Group visibility"
            content={
                <RadioGroup
                    aria-label="quiz"
                    onChange={(e) => setFieldValue('roomVisibility', e.target.value)}
                    value={values.roomVisibility}
                >
                    <FormControlLabel
                        value="private"
                        control={<Radio color="primary" />}
                        label="Private"
                        className={classes.radioItem}
                    />
                    <FormControlLabel
                        value="public"
                        control={<Radio color="primary" />}
                        label="Public"
                        className={classes.radioItem}
                    />
                </RadioGroup>
            }
        />
    );
};
