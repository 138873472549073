import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        confirmText: {
            fontSize: 14,
        },
        button: {
            marginLeft: theme.spacing(1.5),
        },
    };
});
